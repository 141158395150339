import React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button/Button";

interface TitleProps extends MuiButtonProps {
  children: React.ReactNode;
}

const Title: React.FC<TitleProps & WithStyles<"title">> = ({
  children,
  classes
}) => {
  return (
    <Typography variant="h6" gutterBottom className={classes.title}>
      {children}
    </Typography>
  );
};

/**
 * Styled versions,
 * First try to change the variant and add gutterBottom, remove
 * Then style here with title prop
 */
const styles = () => ({
  title: {
    // marginBottom: '2em'
  }
});

export default withStyles(styles)(Title);
