import React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button/Button";

type AppBarTitleProps = MuiButtonProps & { children: React.ReactNode };

const AppBarTitle: React.FC<AppBarTitleProps & WithStyles<"root">> = ({
  children,
  classes
}) => {
  return (
    <Typography variant="h2" className={classes.root}>
      {children}
    </Typography>
  );
};

/**
 * Styled versions,
 * First try to change the variant and add gutterBottom, remove
 * Then style here with title prop
 */
const styles = () => ({
  root: {
    flex: 1
    // marginBottom: '2em'
  }
});

export default withStyles(styles)(AppBarTitle);
