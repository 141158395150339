import React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

interface TextProps extends TypographyProps {
  children?: React.ReactNode;
}

const Text: React.FC<TextProps & WithStyles<"text">> = ({
  children,
  classes,
  ...rest
}) => {
  return (
    <Typography variant="body1" className={classes.text} {...rest}>
      {children}
    </Typography>
  );
};

/**
 * Styled versions,
 * First try to change the variant and add gutterBottom, remove
 * Then style here with title prop
 */
const styles = () => ({
  text: {
    // marginBottom: '2em'
  }
});

export default withStyles(styles)(Text);
