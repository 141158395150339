import React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

type HeaderProps = TypographyProps & {
  children: React.ReactNode;
};

const Header: React.FC<HeaderProps & WithStyles<"headline">> = ({
  children,
  classes,
  ...rest
}) => {
  return (
    <Typography variant="subtitle1" className={classes.headline} {...rest}>
      {children}
    </Typography>
  );
};

/**
 * Styled versions,
 * First try to change the variant and add gutterBottom, remove
 * Then style here with headline prop
 */
const styles = () => ({
  headline: {
    // marginBottom: '2em'
  }
});

const cardStyles = () => ({
  headline: {
    marginTop: 0,
    paddingTop: "20px"
  }
});

export const CardHeader = withStyles(cardStyles)(Header);

export default withStyles(styles)(Header);
