import React from "react";
import H from "history";
import { Link } from "gatsby";
import { ListItem as MuiListItem } from "@material-ui/core";
import { ListItemProps } from "@material-ui/core/ListItem";

type ExtendedListItemProps = {
  to: H.LocationDescriptor;
} & ListItemProps;

const ListItem: React.FC<ExtendedListItemProps> = ({
  to,
  children,
  ...props
}) => {
  return (
    <MuiListItem
      {...props}
      component={(item: any) => (
        <Link to={to as string} {...item}>
          {children}
        </Link>
      )}
    />
  );
};

export default ListItem;
