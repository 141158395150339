import React from "react";
import classNames from "classnames";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { SpecificTheme } from "../../theme/default";
import { compose } from "recompose";

type backgroundColor =
  | "front"
  | "standard"
  | "footer"
  | "team"
  | "blog"
  | "none";

type ContainerColor = "primary" | "footer" | "transparent" | "white";

type ContainerVariant =
  | "standard"
  | "expanded"
  | "expandedUp"
  | "expandedRight";

export const containerStyles = (theme: Theme) => {
  return createStyles({
    margin: {
      [theme.breakpoints.between("md", "lg")]: {
        marginLeft: `${1 * (100 / 24)}%`,
        marginRight: `${1 * (100 / 24)}%`
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: `${2 * (100 / 24)}%`,
        marginRight: `${2 * (100 / 24)}%`
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: `${1 * (100 / 48)}%`,
        marginRight: `${1 * (100 / 48)}%`
      }
    },
    padding: {
      [theme.breakpoints.between("md", "lg")]: {
        paddingLeft: `${1 * (100 / 24)}%`,
        paddingRight: `${1 * (100 / 24)}%`
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: `${2 * (100 / 24)}%`,
        paddingRight: `${2 * (100 / 24)}%`
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: `${1 * (100 / 48)}%`,
        paddingRight: `${1 * (100 / 48)}%`
      }
    }
  });
};

const styles = (theme: SpecificTheme) => {
  const containers = containerStyles(theme);
  return createStyles({
    root: {},
    ...containers,
    // Color versions
    primary: {
      backgroundColor: theme.specific.backgroundFront
    },
    footer: {
      backgroundColor: theme.specific.footer.background
    },
    white: {
      backgroundColor: theme.palette.common.white
    },
    transparent: {
      backgroundColor: "transparent"
    },

    // Special padding versions
    standard: {},
    expanded: {
      paddingRight: 0,
      paddingLeft: 0
    },
    expandedUp: {
      [theme.breakpoints.up("md")]: {
        paddingRight: 0,
        paddingLeft: 0
      }
    },
    expandedRight: {
      [theme.breakpoints.up("md")]: {
        paddingRight: 0
      }
    }
    /*
  standard: {
    backgroundColor: theme.specific.backgroundFront
  },
  footer: {
    backgroundColor: theme.specific.footer.background
  },
  team: {
    backgroundColor: theme.specific.backgroundFront,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  blog: {
    backgroundColor: theme.specific.backgroundFront,
    paddingRight: 0,
    paddingLeft: 0
  },
  front: {
    backgroundColor: theme.specific.backgroundFront,
    [theme.breakpoints.up("md")]: {
      paddingRight: 0
    }
  },
  none: {}
  */
  });
};

type PublicProps = {
  className?: string;
  padding?: boolean;
  margin?: boolean;
  variant: ContainerVariant;
  color: ContainerColor;
};

type ContainerProps = PublicProps & WithStyles<typeof styles>;

const Container: React.FC<ContainerProps> = ({
  color,
  classes,
  children,
  padding,
  margin,
  variant,
  className
}) => {
  const containerStyle = classNames(
    classes.root,
    {
      [classes.margin]: margin,
      [classes.padding]: padding
    },
    {
      // Color versions
      [classes.primary]: color === "primary",
      [classes.footer]: color === "footer",
      [classes.white]: color === "white",
      [classes.transparent]: color === "transparent",

      // Container versions
      [classes.standard]: variant === "standard",
      [classes.expanded]: variant === "expanded",
      [classes.expandedRight]: variant === "expandedRight",
      [classes.expandedUp]: variant === "expandedUp"
    },
    className
  );

  return <div className={containerStyle}>{children}</div>;
};

export default compose<ContainerProps, PublicProps>(withStyles(styles))(
  Container
);
