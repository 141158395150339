import colors from "./colors";

const typography: any = {
  htmlFontSize: 13 /* 62.5% of 16px = 10px */,
  // Changes everything, default 16
  fontFamily: "Roboto, sans-serif",
  // Currently used in general/typo

  h1: {
    fontSize: "3rem"
  },
  h2: {
    fontSize: "1.5rem",
    color: colors.darkGreyText,
    fontWeight: 500,
    marginTop: "1rem",
    marginBottom: "0rem"
  },
  h3: {
    fontSize: "1.4rem",
    color: colors.greyText
  },
  h4: {
    fontSize: "1.25rem"
  },
  subtitle1: {
    fontSize: "1.75rem",
    color: colors.greyText
  },
  // blog
  subtitle2: {
    fontSize: "1.5rem",
    color: colors.greyText
  },

  body1: {
    margin: "0.5rem 0 0.25rem 0",
    fontSize: "1rem"
    // fontSize: '1rem'
  }
};

export default typography;
