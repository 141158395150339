import React from "react";
import { Typography, WithStyles, withStyles } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

type HeaderProps = TypographyProps & {
  children: React.ReactNode;
};

const Subheader: React.FC<HeaderProps & WithStyles<"subheader">> = ({
  children,
  classes,
  ...rest
}) => {
  return (
    <Typography variant="subtitle1" className={classes.subheader} {...rest}>
      {children}
    </Typography>
  );
};

/**
 * Styled versions,
 * First try to change the variant and add gutterBottom, remove
 * Then style here with headline prop
 */
const styles = () => ({
  subheader: {
    // marginBottom: '2em'
  }
});

const cardStyles = () => ({
  subheader: {
    marginTop: 0,
    paddingTop: "2em"
  }
});

export const CardSubheader = withStyles(cardStyles)(Subheader);

export default withStyles(styles)(Subheader);
