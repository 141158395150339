import React from "react";
import GatsbyImage, { GatsbyImageProps } from "gatsby-image";

type Props = {
  image: {
    title: string | null;
    description: string | null;
    // Temp fix until this is resolved -> https://github.com/gatsbyjs/gatsby/issues/17003
    fluid?: any;
    fixed?: any;
  } & Omit<GatsbyImageProps, "title" | "className" | "fluid" | "fixed">;
  className?: string;
};

export const Image: React.FC<Props> = ({
  image: { title, description, ...props },
  className
}) => {
  return (
    <GatsbyImage
      title={title}
      alt={description}
      {...props}
      className={className}
    />
  );
};
