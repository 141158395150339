import React from "react";
import cx from "classnames";
import {
  // Button as MUIButton,
  createStyles,
  WithStyles,
  withStyles
} from "@material-ui/core";
// import { ButtonProps as MuiButtonProps } from "@material-ui/core/Button/Button";
import { Link } from "gatsby";
import { SpecificTheme } from "../../../theme/default";
import { compose } from "recompose";

/**
 * Styled versions
 */
const navStyles = (theme: SpecificTheme) =>
  createStyles({
    root: {
      fontSize: "1.4rem",
      color: theme.overrides.MuiAppBar.colorDefault.color,
      marginLeft: "2em",
      "&:hover": {
        fontWeight: "bold",
        cursor: "pointer",
        textDecoration: "none"
      },
      textDecoration: "none",
      textTransform: "uppercase"
    },
    active: {
      fontWeight: "bold"
    },
    first: {
      marginLeft: 0
    }
  });

const generalStyles = () =>
  createStyles({
    root: {
      // Styles here
    },
    active: {},
    first: {}
  });

type PublicButtonProps = {
  to: string; // H.LocationDescriptor
  first?: boolean;
  active?: boolean;
  style?: React.CSSProperties;
};

type ComponentProps = PublicButtonProps & WithStyles<typeof navStyles>;

const Button: React.FC<ComponentProps> = ({
  children,
  to,
  active,
  first,
  classes,
  style,
  ...props
}) => {
  const className = cx(classes.root, {
    [classes.active]: active,
    [classes.first]: first
  });

  return (
    <Link to={to} className={className} style={style}>
      {children}
    </Link>
  );
};

export const NavButton = compose<ComponentProps, PublicButtonProps>(
  withStyles(navStyles)
)(Button);

export default compose<ComponentProps, PublicButtonProps>(
  withStyles(generalStyles)
)(Button);
