import React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Hidden
} from "@material-ui/core";
import { Image } from "../image/Image";
import { NavButton } from "../general";
import { SpecificTheme } from "../../theme/default";
import Text from "../general/typo/Text";
import { compose } from "recompose";
import { Location } from "history";

interface MainNavigationListProps {
  logo: any;
  location: Location;
}

const styles = (theme: SpecificTheme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "10vh",
      color: theme.overrides.MuiAppBar.colorDefault.color,
      background: theme.overrides.MuiAppBar.colorDefault.backgroundColor,
      paddingRight: `${100 / 24}%`,
      paddingLeft: `${100 / 24}%`,
      [theme.breakpoints.up("xl")]: {
        paddingLeft: `${2 * (100 / 24)}%`,
        paddingRight: `${2 * (100 / 24)}%`
      }
    },
    logo: {
      width: "11em",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    logoImage: {
      width: "90px"
    },
    logoText: {
      color: theme.overrides.MuiAppBar.colorDefault.color,
      textTransform: "uppercase",
      fontWeight: "normal"
    }
  });

type ComponentProps = MainNavigationListProps & WithStyles<typeof styles>;

const MainNavigationList: React.FC<ComponentProps> = ({
  classes,
  logo,
  location
}) => {
  return (
    <div className={classes.root}>
      <div>
        <NavButton
          active={location.pathname === "/"}
          first
          to="/"
          style={{ marginLeft: 0, paddingLeft: 0 }}
        >
          Home
        </NavButton>

        <NavButton active={location.pathname === "/services"} to="/services">
          Services
        </NavButton>

        <NavButton active={location.pathname === "/team"} to="/team">
          Team
        </NavButton>

        <NavButton active={location.pathname === "/projekte"} to="/projekte">
          Projekte
        </NavButton>

        <Hidden lgDown>
          <NavButton
            active={location.pathname === "/dashboard"}
            to="/dashboard"
          >
            Demo
          </NavButton>
        </Hidden>

        <NavButton active={location.pathname === "/kontakt"} to="/kontakt">
          Kontakt
        </NavButton>

        <NavButton active={location.pathname === "/blog"} to="/blog">
          Blog
        </NavButton>
      </div>
      <div className={classes.logo}>
        <Image
          image={{ ...logo, alt: "Twteam Logo", title: "Twteam Logo" }}
          className={classes.logoImage}
        />
        <Text className={classes.logoText}>Twteam</Text>
      </div>
    </div>
  );
};

export default compose<ComponentProps, MainNavigationListProps>(
  withStyles(styles)
)(MainNavigationList);
