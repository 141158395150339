import React from "react";
import classNames from "classnames";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { SpecificTheme } from "../../theme/default";

export type SectionLayoutType = "layout1" | "layout2" | "layout3";

export type SectionType = "dark" | "main" | "light" | "common";

type ClassKeys = "root" | SectionLayoutType | SectionType;

type SectionProps = {
  layout?: SectionLayoutType;
  variant?: SectionType;
  className?: string;
} & WithStyles<typeof styles>;

const Section: React.FC<SectionProps> = ({
  children,
  layout,
  variant,
  classes,
  className
}) => {
  const styleVariant = classNames(
    classes.root,
    {
      [classes.layout1]: layout === "layout1",
      [classes.layout2]: layout === "layout2",
      [classes.layout3]: layout === "layout3",
      [classes.dark]: variant === "dark",
      [classes.light]: variant === "light",
      [classes.common]: variant === "common",
      [classes.main]: variant === "main"
    },
    className
  );
  return <section className={`${styleVariant}`}>{children}</section>;
};

/**
 * Styled versions
 */
const styles = (theme: SpecificTheme) =>
  createStyles({
    root: {
      padding: "10px 40px",
      display: "flex",
      flexDirection: "column"
    },
    layout1: {
      alignItems: "center",
      alignContent: "space-between",
      textAlign: "center"
    },
    layout2: {
      alignItems: "center",
      alignContent: "stretch"
    },
    layout3: {
      alignItems: "flex-end",
      alignContent: "space-around",
      textAlign: "right"
    },

    dark: {
      ...theme.specific.section.dark
    },
    main: {
      ...theme.specific.section.main
    },
    light: {
      ...theme.specific.section.light
    },
    common: {
      ...theme.specific.section.common
    }
  });

export default withStyles(styles)(Section);
