import React from "react";
import { compose } from "recompose";
import cx from "classnames";
import {
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Hidden
} from "@material-ui/core";
import { SpecificTheme } from "../../theme/default";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import Container from "./Container";
import { useStaticQuery, graphql } from "gatsby";
import { FooterData } from "./__generated__/FooterData";
import { Image } from "../image/Image";

type PublicProps = {};

const styles = (theme: SpecificTheme) =>
  createStyles({
    common: {
      backgroundColor: theme.specific.footer.background,
      color: theme.specific.footer.color,
      a: {
        color: theme.specific.footer.color
      }
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        fontWeight: "bold"
      }
    },
    desktop: {
      height: "8vh"
    },
    mobile: {
      paddingTop: "0.5vh",
      paddingBottom: "0.5vh",
      minHeight: "10vh"
    },
    center: {}
  });

type ComponentProps = PublicProps & WithStyles<typeof styles>;

const Footer: React.FC<ComponentProps> = ({ classes, children }) => {
  const footerData = useStaticQuery<FooterData>(graphql`
    query FooterData {
      swissmadeLogo: contentfulImages(name: { eq: "swissmadesoftware" }) {
        id
        name
        image {
          title
          description
          fixed(height: 30) {
            ...ContentfulFixedImage
          }
        }
      }
    }
  `);

  const desktopStyle = cx(classes.common, classes.desktop);
  const mobileStyle = cx(classes.common, classes.mobile);

  const linkStyle = cx(classes.common, classes.link);

  const email = (
    <a href="mailto:info@twteam.ch" className={linkStyle}>
      <FontAwesomeIcon icon={faEnvelope} /> info@twteam.ch
    </a>
  );

  const phone = (
    <a href="tel:+41628782330" className={linkStyle}>
      <FontAwesomeIcon icon={faPhone} /> +41 62 878 23 30
    </a>
  );

  return (
    <Container variant="standard" padding color="footer">
      {/* Smartphone and tables */}
      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          className={mobileStyle}
        >
          <Grid item style={{ marginRight: "10vw", paddingTop: "10px" }}>
            {email}
          </Grid>
          <Grid item style={{ marginRight: "10vw", paddingTop: "10px" }}>
            {phone}
          </Grid>
          {footerData && footerData.swissmadeLogo.image && (
            <Grid item style={{ marginRight: "10vw", paddingTop: "20px" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.swissmadesoftware.org/"
              >
                <Image image={footerData.swissmadeLogo.image} />
                {/*
                <GatsbyImage
                  alt={footerData.swissmadeLogo.name}
                  fixed={footerData.swissmadeLogo.image.fixed}
                ></GatsbyImage>
                */}
              </a>
            </Grid>
          )}
        </Grid>
      </Hidden>

      {/* Desktop */}
      <Hidden smDown>
        <Grid
          container
          justify={"space-between"}
          alignItems={"center"}
          className={desktopStyle}
        >
          <Grid item>triple w team AG</Grid>

          <Grid item>{email}</Grid>

          <Grid item>{phone}</Grid>

          {footerData && footerData.swissmadeLogo.image && (
            <Grid item style={{ paddingTop: "10px" }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.swissmadesoftware.org/de/home/companies/triple-w-team-ag/home.html"
              >
                <Image
                  image={{
                    ...footerData.swissmadeLogo.image,
                    description: "Swissmade Partner Logo",
                    title: "Swissmade Partner Logo"
                  }}
                />
                {/*
                <GatsbyImage
                  alt={footerData.swissmadeLogo.name}
                  fixed={footerData.swissmadeLogo.image.fixed}
                ></GatsbyImage>
                */}
              </a>
            </Grid>
          )}
        </Grid>
      </Hidden>
    </Container>
  );
};

export default compose<ComponentProps, PublicProps>(withStyles(styles))(Footer);
