/**
 * Colors to use for all MUI components
 */
export interface CustomColor {
  dark: string;
  main: string;
  light: string;
  common: string;

  whiteText: string;
  greyText: string;
  darkGreyText: string;

  // LogoColor
  lightPetrol: string;
  mediumPetrol: string;
  darkPetrol: string;
  gold: string;
  goldDark: string;
  goldLight: string;
}

const customColor: CustomColor = {
  dark: "#f4eee9",
  main: "#f5f5f5",
  light: "#e0f2f1",
  common: "#eee8ec",
  // lightPetrol: '#91bbc8',
  // lightPetrol: "#bf9b30",
  lightPetrol: "#98B6C1",
  mediumPetrol: "#1A728D", // "#0C607B",
  darkPetrol: "#13242D",
  gold: "#BF9B30",

  goldDark: "#8b6d00",
  goldLight: "#f5cc60",

  whiteText: "#fff",
  greyText: "#808080",

  darkGreyText: "#505050"
};

export default customColor;
