import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import red from "@material-ui/core/colors/red";
import { colors } from ".";

const palette: PaletteOptions = {
  primary: {
    light: "#1994af",
    main: "#19718c",
    dark: "#145369"
  },
  secondary: {
    light: colors.lightPetrol,
    main: colors.mediumPetrol,
    dark: colors.darkPetrol
  },
  error: {
    light: red[200],
    main: red[400],
    dark: red[600]
  },
  // Text color intensity
  text: {
    primary: "rgba(68, 68, 68, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)"
  },
  background: {
    paper: "#fff",
    default: "#fafafa"
  },
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.14)",
    selected: "rgba(0, 0, 0, 0.08)",
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)"
  },
  // default "rgba(0, 0, 0, 0.12)",
  divider: "rgba(0, 0, 0, 0.12)"
};

export default palette;
