import React from "react";
import createStyles from "@material-ui/styles/createStyles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import withStyles, { WithStyles } from "@material-ui/styles/withStyles";
import { Location } from "history";
import { compose } from "recompose";
import { graphql, StaticQuery } from "gatsby";
import NavigationBar from "../navigation/NavigationBar";
import MainNavigationList from "../navigation/MainNavigationList";
import { SpecificTheme } from "../../theme/default";
import { WithRootProps } from "../../helpers/withMaterial";

// import Footer from "../general/Footer";
// import { logoFragment } from "../../helpers/fragments";

type PublicProps = {
  title: string;
  location: Location;
};

const styles = (theme: SpecificTheme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    appBarGrid: {
      overflow: "hidden"
    },
    navigationBarGrid: {
      overflow: "hidden"
    },
    contentGrid: {
      overflow: "hidden",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginTop: "66px"
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: "60px"
      }
    }
  });

type ComponentProps = {} & PublicProps &
  WithRootProps &
  WithStyles<typeof styles>;

const MainLayout: React.FC<ComponentProps> = ({
  classes,
  children,
  title,
  location
}) => (
  <div className={classes.root}>
    <StaticQuery
      query={graphql`
        query Logo {
          logo: imageSharp(original: { src: { regex: "/logoTWT/" } }) {
            fluid(maxHeight: 160, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      `}
      render={data => (
        <Grid container spacing={0}>
          <Hidden smDown>
            <Grid item xs={12} className={classes.navigationBarGrid}>
              <MainNavigationList location={location} logo={data.logo} />
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <Grid item xs={12} sm={12}>
              <NavigationBar title={title} logo={data.logo} />
            </Grid>
          </Hidden>

          <Grid item xs={12} className={classes.contentGrid}>
            {children}
          </Grid>
        </Grid>
      )}
    />
  </div>
);

export default compose<ComponentProps, PublicProps>(
  withStyles(styles)
  // withMaterialUI
)(MainLayout);
